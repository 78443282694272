import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import * as authApi from '../../../apis/authApi';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import store from './../../index';

import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"
import { loadProfile } from "../profile/actions";

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  // try {
  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const response = yield call(
  //       fireBaseBackend.loginUser,
  //       user.email,
  //       user.password
  //     )
  //     yield put(loginSuccess(response))
  //   } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
  //     const response = yield call(postJwtLogin, {
  //       email: user.email,
  //       password: user.password,
  //     })
  //     localStorage.setItem("authUser", JSON.stringify(response))
  //     yield put(loginSuccess(response))
  //   } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
  //     const response = yield call(postFakeLogin, {
  //       username: user.username,
  //       password: user.password,
  //     })
  //     console.log(response);
  //     localStorage.setItem("authUser", JSON.stringify(response))
  //     yield put(loginSuccess(response))
  //   }

  //   history.push("/dashboard")
  // } catch (error) {
  //   console.log(error);
  //   yield put(apiError(error))
  // }
  authApi.auth(
    user.username, user.password,
    (res) => {authSuccess(res,history);},
    (res) => {authErr(res);}
  )
}
function authSuccess (res,history) {
  localStorage.setItem("authUser", JSON.stringify(res.data.user))
  localStorage.setItem("token", res.data.access_token)
  localStorage.setItem("refresh-token", res.data.refresh_token)
  store.dispatch(loginSuccess(res.data.user))
  store.dispatch(loadProfile(res.data.user))
  history.push("/inventories")
}
function authErr (res) {
  store.dispatch(apiError([res.message]))
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.clear()

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/inventories")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
